import React from 'react'

const Marks = () => {
  return (
    <div>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-1 lg:grid-cols-2 mt-4 max-w-[1200px] mx-auto">
        <div
          data-aos="fade-down"
          data-aos-easing="ease-in-out"
          dir="rtl"
          className="text-right bg-black bg-opacity-50 flex justify-between items-start w-[70%]  sm:max-w-[380px]  border-[1px] rounded-3xl border-secondary p-6 mt-8 mx-auto text-main  max-w-[600px] "
        >
          <h3 className="text-5xl md:text-7xl font-extrabold ml-4 ">1</h3>
          <p className="text-2xl flex-1 md:text-2xl font-bold mt-4">
            <span className="text-secondary">מיתוג שיווקי מכירתי ואמין</span>

            {/* -   אנחנו באים לדבר תכלס  */}
          </p>
        </div>

        <div
          data-aos="fade-down"
          data-aos-easing="ease-in-out"
          dir="rtl"
          className="text-right bg-black bg-opacity-50 flex justify-between items-start  w-[70%] sm:max-w-[380px]  border-[1px] rounded-3xl border-secondary p-6 mt-8 mx-auto text-main  max-w-[600px] "
        >
          <h3 className="text-5xl md:text-7xl font-extrabold ml-4 ">2</h3>
          <p className="text-2xl flex-1 md:text-2xl font-bold mt-4">
            <span className="text-secondary">משפך לידים איכותי </span>

            {/* - ייחודיות ואותנטיות מביאים לתוצאות */}
          </p>
        </div>

        {/* <div   data-aos="fade-down"
                    data-aos-easing="ease-in-out" dir='rtl' className="text-right flex justify-between items-start   border-[1px] rounded-3xl border-secondary p-6 mt-8 mx-auto text-main  max-w-[600px] ">

                    <h3 className="text-5xl md:text-7xl font-extrabold ml-4 ">3</h3>
                    <p className="text-2xl flex-1 md:text-2xl font-bold mt-4">
                        אין כאן סרטונים מ-AliExpress צילום והפקה זה מקצוע שדורש
                        <span className='text-secondary'> חשיבה מחוץ לקופסא</span>

                        , תכנון הלוקיישן, קריאייטיב ויצירתיות
                    </p>
                </div>


                <div   data-aos="fade-down"
                    data-aos-easing="ease-in-out" dir='rtl' className="text-right flex justify-between items-start   border-[1px] rounded-3xl border-secondary p-6 mt-8 mx-auto text-main  max-w-[600px] ">

                    <h3 className="text-5xl md:text-7xl font-extrabold ml-4 ">4</h3>
                    <p className="text-2xl flex-1 md:text-2xl font-bold mt-4">
                        <span className='text-secondary'>איזון זה שם המשחק </span>

                        - גם בתוכן, גם בצילום וגם בעריכה                        </p>
                </div> */}
      </div>
    </div>
  )
}

export default Marks
