import React, { useEffect } from 'react'
import HomePage from './components/HomePage'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import SucessPage from './components/SucessPage'

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    })
  }, [])

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/success" element={<SucessPage />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
