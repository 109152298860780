import React from 'react'
import { Link } from 'react-router-dom'
import { FaCheckCircle, FaHome } from 'react-icons/fa'
import logo from '../images/moovemedia_logo.png'

export default function SuccessPage() {
  return (
    <section dir="rtl" className="flex flex-col items-center justify-center min-h-screen my-container text-white p-4">
      <div className="text-center max-w-md mx-auto">
        <img
          data-aos="fade-down"
          data-aos-easing="ease-in-out"
          src={logo}
          className="w-[200px] px-4 mb-4 mx-auto"
          alt="logo"
        />
        <FaCheckCircle className="text-6xl mb-6 text-green-300 mx-auto" />
        <h1 className="text-3xl font-bold mb-4">קיבלנו את הטופס שלך בהצלחה</h1>
        <p className="text-xl mb-8">תודה על פנייתך. נחזור אליך בהקדם.</p>
        <Link
          dir="ltr"
          to="/"
          className="inline-flex items-center justify-center px-6 py-3 text-lg font-medium text-blue-700 bg-white rounded-full hover:bg-blue-100 transition duration-300 ease-in-out"
        >
          <FaHome className="mr-2" />
          חזרה לאתר
        </Link>
      </div>
    </section>
  )
}
